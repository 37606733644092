import { render, staticRenderFns } from "./Link.vue?vue&type=template&id=f9fa7d7a&functional=true"
import script from "./Link.vue?vue&type=script&lang=js"
export * from "./Link.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

export default component.exports