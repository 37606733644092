var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apos-area-modify-controls"},[_c('AposButtonGroup',{attrs:{"modifiers":[ 'vertical' ]}},[(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"disabled":_vm.first || _vm.disabled,"tooltip":{
        content: (!_vm.disabled && !_vm.first) ? 'apostrophe:nudgeUp' : null,
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('up')}}},'AposButton',_vm.upButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign && !_vm.options.contextual)?_c('AposButton',_vm._b({attrs:{"disabled":_vm.disabled,"tooltip":{
        content: 'apostrophe:editWidget',
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('edit')}}},'AposButton',_vm.editButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"tooltip":{
        content: 'apostrophe:cut',
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('cut')}}},'AposButton',_vm.cutButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"tooltip":{
        content: 'apostrophe:copy',
        placement: 'left'
      }},on:{"click":function($event){return _vm.$emit('copy')}}},'AposButton',_vm.copyButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"disabled":_vm.disabled || _vm.maxReached,"tooltip":{
        content: 'apostrophe:duplicate',
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('clone')}}},'AposButton',_vm.cloneButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"disabled":_vm.disabled,"tooltip":{
        content: 'apostrophe:delete',
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('remove')}}},'AposButton',_vm.removeButton,false)):_vm._e(),_vm._v(" "),(!_vm.foreign)?_c('AposButton',_vm._b({attrs:{"disabled":_vm.last || _vm.disabled,"tooltip":{
        content: (!_vm.disabled && !_vm.last) ? 'apostrophe:nudgeDown' : null,
        placement: 'left'
      },"modifiers":[ 'inline' ]},on:{"click":function($event){return _vm.$emit('down')}}},'AposButton',_vm.downButton,false)):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }