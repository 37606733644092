import { render, staticRenderFns } from "./AposWidgetControls.vue?vue&type=template&id=25ab692b&scoped=true"
import script from "./AposWidgetControls.vue?vue&type=script&lang=js"
export * from "./AposWidgetControls.vue?vue&type=script&lang=js"
import style0 from "./AposWidgetControls.vue?vue&type=style&index=0&id=25ab692b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ab692b",
  null
  
)

export default component.exports